<template>
	<div>
		<Header :navbg='true'></Header>
		<div class="content">
			<div class="banner">
				<img src="../../assets/images/milestone/memorabilia-banner.jpg" alt="">
			</div>
			<div class="fzlc">
				<div class="list-warp">
					<div class="warp" v-for="(items,indexs) in timeList" :key="indexs">
						<div class="year">{{items.year}}</div>
						<div class="item" v-for="(item,index) in items.eventList" :key="index">
							<div class="time">{{item.month}}</div>
							<div class="title">{{item.title}}</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/head2022.vue'
	import Footer from '@/components/foot2022.vue'

	export default {
		name: "lifeMilestone",
		data() {
			return {
				timeList: [{
						year: "2020",
						eventList: [
							{
								month: "9月20",
								title: "小院生活·Y Cafe 门店开业",
							},
							{
								month: "2月10",
								title: "小院生活向渭南经开管委会捐赠十万元抗疫物资",
							},
							{
								month: "1月23",
								title: "小院生活·长安十三茶芙蓉园龙舫店开业",
							},
						],
					},

					{
						year: "2019",
						eventList: [{
								month: "10月15",
								title: "渭南小院·经开未来城项目奠基开工",
							},
							{
								month: "9月20",
								title: "小院生活馆MOMOPARK华夫茶饼店开业",
							},
							{
								month: "8月30",
								title: "小院生活馆渭南万达广场店开业",
							},
							{
								month: "7月20",
								title: "小院生活馆渭南宏帆广场店开业",
							},
						],

					},



					{
						year: "2018",
						eventList: [{
								month: "12月25",
								title: "小院生活 “水一方·小院”项目完全竣工",
							},
							{
								month: "11月17",
								title: "公司总裁袁国谦参加第五届中国企业全球化论坛",
							},
							{
								month: "3月6",
								title: "公司总裁袁国谦参加首届中英人居环境峰会论坛",
							},

						],

					},


					{
						year: "2017",
						eventList: [{
								month: "12月1",
								title: "小院生活 “水半湾·小院”项目完全竣工",
							},
							{
								month: "10月20",
								title: "小院生活“奥韵华府·小院”项目正式开工",
							},
							{
								month: "8月20",
								title: "公司总裁袁国谦应邀参加首届世界西商大会",
							},


						],

					},

					{
						year: "2016",
						eventList: [{
								month: "4月",
								title: "伦敦大学建筑学院副院长DAVID到小院生活参观交流",
							},
							{
								month: "4月",
								title: "交大创新港公司到小院生活参观考察",
							},
							{
								month: "1月",
								title: "英国皇家建筑设计研究院曹春丽博士一行到访小院生活",
							},
						],

					},

					{
						year: "2015",
						eventList: [{
								month: "8月20",
								title: "西安小院科技股份有限公司正式注册成立",
							},
							{
								month: "3月1",
								title: "小院生活筹备组首次召开股东大会",
							},
						],

					},


				]
			}
		},
		components: {
			Header,
			Footer,
		}
	}
</script>

<style lang="scss" scoped>
	.banner {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.fzlc {
		padding: 20px 16px 50px;
	}

	.fzlc .list-warp {
		padding-left: 7px;
	}

	.fzlc .list-warp .warp {
		border-left: 1px solid #dcdcdc;
		padding: 20px 0 4px 25px;
		position: relative;
		margin-top: 40px;
	}

	.fzlc .list-warp .warp::after {
		background-color: #ffffff;
		content: ' ';
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 10px;
		left: -5px;
		top: 24px;
	}

	.fzlc .list-warp .warp::before {
		background-color: #00cb87;
		content: ' ';
		position: absolute;
		width: 14px;
		height: 14px;
		border-radius: 14px;
		left: -7px;
		top: 22px;
	}

	.fzlc .list-warp .warp .year {
		position: absolute;
		top: -25px;
		left: -10px;
		font-size: 18px;
		color: #dcdcdc;
	}

	.fzlc .list-warp .warp .item {
		margin-top: 20px;
	}

	.fzlc .list-warp .warp .item .time {
		font-size: 14px;
		color: #dcdcdc;
	}

	.fzlc .list-warp .warp .item .title {
		font-size: 14px;
		color: #303233;
	}

	.fzlc .list-warp .warp .item .img-warp {
		width: 100%;
		position: relative;
		margin-top: 8px;
	}

	.fzlc .list-warp .warp .item .img-warp img {
		width: 100%;
	}

	.fzlc .list-warp .warp .item .img-warp .desc {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: #ffffff;
		font-size: 14px;
		padding: 7px 15px;
		box-sizing: border-box;
	}
</style>
